<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="basketWithIndex"
      class="elevation-1"
      item-key="id"
      :loading="loading"
      disable-pagination
      hide-default-footer
    >
      <template #item.active="{ item }">
        <v-switch
          v-model="item.active"
          :label="`${item.active ? 'Active' : 'Inactive'}`"
          :color="`${item.active ? 'success' : null}`"
          disabled
        >
        </v-switch>
      </template>
      <template #item.alternatives="{ item }">
        <template v-if="item.alternatives">
          <v-menu offset-x open-on-hover left transition="slide-x-transition">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-on="on"
                v-bind="attrs"
                plain
                class="body-2 text-capitalize"
              >
                click to view alternatives
              </div>
            </template>
            <v-sheet class="pa-2">
              <template v-for="(alternative, i) in item.alternatives">
                <v-radio-group v-model="column" column :key="i">
                  <v-radio disabled v-model="alternative.item">
                    <template v-slot:label>
                      <div class="primary--text">
                        {{ alternative.item }}
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </template>
            </v-sheet>
          </v-menu>
        </template>
      </template>
      <template #item.action="{ item }">
        <v-btn color="grey" icon @click="editQuestionBtn(item, 'edit')">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn color="grey" icon @click="deleteQuestionBtn(item, 'delete')">
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <!-- Create New Staff -->
    <v-navigation-drawer
      clipped
      overlay-color="black"
      v-model="question"
      fixed
      :permanent="question"
      right
      width="40%"
      class="shadow"
    >
      <v-card flat class="mt-16">
        <v-toolbar tile color="primary" dark flat>
          <b class="text-uppercase">{{ statusType }} QUESTION </b>
          <v-spacer></v-spacer>

          <v-btn @click="question = false" icon>
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-toolbar>

        <AddQuestion
          v-if="question"
          :questionData="questionData"
          :statusType="statusType"
          @deleteQuestion="deleteQuestion"
        />
      </v-card>
    </v-navigation-drawer>
  </v-container>
</template>
<script>
  import {
    computed,
    defineComponent,
    // provide,
    reactive,
    toRefs,
  } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import AddQuestion from "@/components/QualityAssurance/AddQuestion";
  export default defineComponent({
    components: { AddQuestion },
    setup() {
      const questionsTable = reactive({
        loading: true,
        headers: [
          { text: "No.", value: "index" },
          { text: "QUESTION", value: "question" },
          { text: "RESPONSE TYPE", value: "type" },
          { text: "ALTERNATIVES", value: "alternatives" },
          { text: "STATUS", value: "active" },
          { text: "ACTIONS", value: "action" },
        ],
        column: null,
        question: false,
        basket: [],
        statusType: "",
        questionData: {},
      });

      const { loading, question, statusType, questionData } =
        toRefs(questionsTable);
      const { getQuestions } = useActions(["getQuestions"]);
      const { getters_questions } = useGetters(["getters_questions"]);

      getQuestions().then(() => (loading.value = false));

      const basketWithIndex = computed(() =>
        getters_questions.value.map((item, index) => ({
          ...item,
          index: index + 1,
        }))
      );

      const editQuestionBtn = (item, edit) => {
        question.value = true;
        statusType.value = edit;
        questionData.value = item;
      };

      const deleteQuestionBtn = (data, item) => {
        question.value = true;
        questionData.value = data;
        statusType.value = item;
      };

      const deleteQuestion = (bool) => (bool ? (question.value = false) : true);

      return {
        getQuestions,
        basketWithIndex,
        ...toRefs(questionsTable),
        getters_questions,
        editQuestionBtn,
        deleteQuestionBtn,
        deleteQuestion,
      };
    },
  });
</script>
