<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-btn
          color="primary"
          class="font-weight-bold"
          v-if="getters_abilities.includes('save_question_access')"
          @click="newQuestion"
        >
          Add New Question
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <Questions />
      </v-col>
    </v-row>

    <v-navigation-drawer
      clipped
      overlay-color="black"
      v-model="question"
      fixed
      :permanent="question"
      right
      width="40%"
      class="shadow"
    >
      <v-card flat class="mt-16">
        <v-toolbar tile color="primary" dark flat>
          <b class="text-uppercase">Add Question </b>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2"
            @click="closeDrawer;"
            icon
            to="/quality_assurance_management/questions"
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-toolbar>

        <AddQuestion v-if="question" />
      </v-card>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
  import {
    computed,
    defineComponent,
    getCurrentInstance,
    reactive,
    toRefs,
  } from "vue";
  import AddQuestion from "@/components/QualityAssurance/AddQuestion";
  import { useGetters } from "vuex-composition-helpers";
  import Questions from "@/components/QualityAssurance/Questions";
  export default defineComponent({
    components: { AddQuestion, Questions },
    setup() {
      const vm = getCurrentInstance();
      const { getters_abilities } = useGetters(["getters_abilities"]);
      const questionTemplate = reactive({
        question: false,
        basket: [],
      });

      const { question, basket } = toRefs(questionTemplate);

      setTimeout(() => {
        switch (vm.proxy.$route.name) {
          case "AddQuestion":
            question.value = true;
            break;

          default:
            break;
        }
      });

      const newQuestion = () => {
        question.value = true;
        vm.proxy.$router.replace({
          path: "/quality_assurance_management/questions/:/addquestion",
        });
      };

      const closeDrawer = computed(() => {
        question.value = false;
        basket.value = [];
      });

      return {
        newQuestion,
        closeDrawer,
        ...toRefs(questionTemplate),
        getters_abilities,
      };
    },
  });
</script>


